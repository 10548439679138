import React from "react";
import { MdHelp, MdOutlinePassword } from "react-icons/md";
import {
  BsFillPersonFill,
  BsFillInfoCircleFill,
  BsFillPencilFill,
  BsFillCheckSquareFill,
} from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { AiOutlineForm, AiOutlinePlus } from "react-icons/ai";
import { RiLogoutBoxLine } from "react-icons/ri";
import {
  FaChevronRight,
  FaChevronDown,
  FaAddressCard,
  FaBackspace,
  FaSave,
  FaRegMinusSquare,
  FaRegPlusSquare,
  FaHome,
} from "react-icons/fa";
import {
  MdOutlinePersonSearch,
  MdPersonRemoveAlt1,
  MdExitToApp,
} from "react-icons/md";
import {
  TbHistory, 
  TbReportAnalytics
} from "react-icons/tb";
// INSTEAD OF IMPORTING EACH ICON IN EACH FILE WE ARE MANAGING ALL ICONS IN THE ICON DRAWER
const IconDrawer = ({ type, className }: any) => {
  switch (type) {
    case "1":
      return <MdOutlinePassword className={className} />;
    case "2":
      return <FaAddressCard className={className} />;
    case "3":
      return <AiOutlineForm className={className} />;
    case "4":
      return <MdOutlinePersonSearch className={className} />;
    case "5":
      return <MdPersonRemoveAlt1 className={className} />;
    case "6":
      return <MdExitToApp className={className} />;
    case "7":
      return <BsFillPersonFill className={className} />;
    case "8":
      return <FaAddressCard className={className} />;
    case "9":
      return <FaChevronRight className={className} />;
    case "10":
      return <RiLogoutBoxLine className={className} />;
    case "11":
      return <BiEdit className={className} />;
    case "12":
      return <FaBackspace className={className} />;
    case "13":
      return <FaSave className={className} />;
    case "14":
      return <AiOutlinePlus className={className} />;
    case "15":
      return <BsFillInfoCircleFill className={className} />;
    case "16":
      return <BsFillPencilFill className={className} />;
    case "17":
      return <BsFillCheckSquareFill className={className} />;
    case "18":
      return <FaChevronDown className={className} />;
    case "19":
      return <FaRegMinusSquare className={className} />;
    case "20":
      return <FaRegPlusSquare className={className} />;
    case "history":
      return <TbHistory className={className} />;
    case "home":
      return <FaHome className={className} />;
    case "help"://adding help icon
      return <MdHelp className={className} />;
    default:
      return <></>;
  }
};

export default IconDrawer;
